Drupal.behaviors.siteHeaderFormatterV1 = (function ($) {
  var $html = $();
  var $body = $();
  var $template = $();
  var hideHeaderOnScroll = false;
  var $searchIcons = $();
  var $mobileSearchInput = $();
  var $gnavHamburger = $();
  var $mobileGnavTrigger = $();
  var $gnavMainSectionWrapper = $();
  var $gnavMobileSectionsMenu = $();
  var $gnavMainSection = $();
  var $gnavMainSectionContent = $();
  var $gnavMainSectionTrigger = $();
  var $mainSectionTriggerReset = $();
  var $triggerResetLabel = $();
  var $allSectionGroups = $();
  var $allSectionGroupContent = $();
  var $gnavLinks = $();
  // Calculate desktop for mobile menu stuff.
  var bps = Unison.fetch.all();
  var bp = Unison.fetch.now();
  var isDesktop;
  var position = $(window).scrollTop();
  var lastScrollTop = 0;
  var currentBpWidth = 0;
  var bPlargeUp = 0;
  var behavior = {
    attached: false,
    attach: function (context) {
      var self = this;

      if (this.attached) {
        return;
      }
      this.attached = true;
      self.isMobile = self.checkIfMobile();
      $html = $('html');
      $body = $('body');
      $template = $('.js-site-header-formatter-v1', context);
      $searchIcons = $('.header-search__icon', context);
      $mobileSearchInput = $('.js-site-header-formatter-search-icon-wrapper', context);
      $gnavHamburger = $('.js-gnav-mobile-trigger', $template);
      $mobileGnavTrigger = $('#gnav_mobile_trigger_input', $template);
      $gnavMainSectionWrapper = $('.js-site-header-formatter__sections', $template);
      $gnavComponentWrapper = $('.js-site-header-formatter-header', $template);
      $gnavMobileSectionsMenu = $(
        '.js-site-header-formatter__mobile-menu-header',
        $gnavMainSectionWrapper
      );
      $gnavMainSection = $('.js-header-nav-section__section-v1', $gnavMainSectionWrapper);
      $gnavMainSectionContent = $('.js-header-nav-section__section-content', $gnavMainSection);
      $gnavMainSectionTrigger = $('.js-header-nav-section__section-label', $gnavMainSection);
      $mainSectionTriggerReset = $('#gnav_section_trigger_reset', $template);
      $triggerResetLabel = $('.js-gnav-section-trigger-reset', $template);
      // These elements are in gnav_section_formatter_v1, we collapse them too when gnav is closed.
      $allSectionGroups = $('.js-gnav-section-formatter-v1', $template);
      $allSectionGroupContent = $('.js-gnav-section-formatter__content-wrapper', $allSectionGroups);
      // These elements are in gnav_links_v1.
      $gnavLinks = $gnavMainSectionWrapper.find('.js-gnav-links-trigger');
      hideHeaderOnScroll = $template.hasClass('hide-site-header-on-scroll');
      if ($.cookie('hide_header_offer_banner')) {
        $template.toggleClass('header-offers-banner-hidden', true);
      }

      const urlParams = new URLSearchParams(window.location.search);
      const hideGNavParam = urlParams.get(Drupal.settings.hideGNav.param);
      const pathName = window.location.pathname;
      const hideGNavPath = Drupal.settings.hideGNav.path;

      if( hideGNavParam && pathName.includes(hideGNavPath)){
        $gnavComponentWrapper.addClass('hidden');
      } else {
        $gnavComponentWrapper.removeClass('hidden');
      }

      var $siteHeader = $('.js-site-header__wrapper', context);
      var $siteContent = $('.site-container', context);
      // do not update to a js- class without confirming no locals are still using html in CMS
      var $subMenu = $siteContent.find('.submenu--sticky');
      var $bestsellersSubMenu = $siteContent.find('.bestsellers-nav');
      var $subMenuLinks = $subMenu.find('.submenu__nav li');
      var $secondaryNav = $siteContent.find('.js-gnav-secondary-formatter-v1');
      var $secondaryNavLinks = $secondaryNav.find('.js-gnav-content-link');
      var $secondaryNavImages = $secondaryNav.find('.js-gnav-tout__image');
      var $headerFormatterInner = $('.js-site-header-formatter-inner', $template);
      var headerFormatterInnerHeight = $headerFormatterInner.outerHeight(true);
      var $heroBlockFormatter = $siteContent.find('.js-content-block-formatter').first();
      var $customGrids = $siteContent.find(
        '.js-mpp-custom-override-layout, .js-mpp-custom-product-layout-3x-v1'
      );
      var $firstSubMenuLink = $subMenuLinks.first();

      if ($firstSubMenuLink.hasClass('active-trail')) {
        $firstSubMenuLink.addClass('active');
      }
      if ($bestsellersSubMenu.length > 0) {
        $firstSubMenuLink.addClass('active');
      }

      function _navHeight() {
        if (!!isDesktop) {
          headerFormatterInnerHeight = $headerFormatterInner.outerHeight(true);
          $template.css('height', headerFormatterInnerHeight);
          $allSectionGroupContent.css('top', headerFormatterInnerHeight);
          $triggerResetLabel.css('top', headerFormatterInnerHeight);
          if ($siteContent.hasClass('gnav-transparent')) {
            $siteContent.css('margin-top', -1 * headerFormatterInnerHeight);
            _alignFirstContentBlock($heroBlockFormatter, headerFormatterInnerHeight);
          }
          if ($subMenu.length > 0) {
            $subMenu.css({ top: $headerFormatterInner.outerHeight(true) + 'px' });
          }
          if (
            $secondaryNavLinks &&
            $secondaryNavLinks.length > 0 &&
            $secondaryNavImages &&
            $secondaryNavImages.length === 0 &&
            $secondaryNav.offset().top === 0
          ) {
            $secondaryNav.css({ top: headerFormatterInnerHeight });
          }
        } else {
          //reset overrides
          $allSectionGroupContent.css('top', '');
          $triggerResetLabel.css('top', '');
          if ($siteContent.hasClass('gnav-transparent')) {
            $siteContent.css('margin-top', '');
            $heroBlockFormatter.css('padding-top', '');
          }
        }
      }
      _navHeight();

      function _alignFirstContentBlock($heroBlockFormatter, headerFormatterInnerHeight) {
        // rest the inline rule
        $heroBlockFormatter.css('padding-top', '');
        var $toolbar = $('#toolbar');
        // Make sure toolbar is checked so content loads under revision on CMS like it will after publish.
        var offsetLimit = $toolbar.length > 0 ? $toolbar.first().height + 10 : 10;
        if ($heroBlockFormatter.length < 1 || $heroBlockFormatter.offset().top > offsetLimit) {
          // we only handle first content block of the page, if any are available
          return;
        }
        var padding = $heroBlockFormatter.css('padding');
        var paddingTop = 0;
        if (typeof padding !== 'undefined' && padding !== '') {
          paddingTop = padding.split(' ')[0];
        }
        if (paddingTop.indexOf('px') !== -1) {
          paddingTop = headerFormatterInnerHeight - parseFloat(paddingTop.replace(/^\D+/g, ''));
          if (paddingTop > 5) {
            // we override only when the existing padding is smaller
            $heroBlockFormatter.css('padding-top', headerFormatterInnerHeight + 'px');
          }
        } else {
          $heroBlockFormatter.css('padding-top', headerFormatterInnerHeight + 'px');
        }
      }

      function _setSubMenuPosition() {
        var templateHeight =
          $siteHeader.hasClass('site-header-formatter--hidden site-header-formatter--sticky') ||
          $siteHeader.hasClass('site-header-formatter--sticky site-header-formatter--hidden')
            ? ''
            : $template.outerHeight(true) + 'px';

        $subMenu.css({ top: $headerFormatterInner.outerHeight(true) + 'px' });
        if (position > lastScrollTop) {
          $subMenu.css({ top: '0px' });
        }
        lastScrollTop = position;
      }

      function _updateNavStatus() {
        var scroll = $(window).scrollTop();
        var templateHeight = $template.outerHeight(true);
        var headerHeight = $('.js-site-header-formatter-header', context).outerHeight(true);

        if (!self.isMobile || (self.isMobile && !$html.hasClass('active-gnav'))) {
        // If the user scroll down, hide the Nav
          if (scroll <= position) {
            $siteHeader.toggleClass('site-header-formatter--hidden', false);
          } else if (scroll > 5) {
            $siteHeader.toggleClass('site-header-formatter--hidden', true);
            $siteHeader.toggleClass('site-header-formatter--sticky', true);
          }
        }

        var scrollHeight = $siteHeader.hasClass('gnav-transparent') ? headerHeight : templateHeight;
        if (scroll <= scrollHeight) {
          $siteHeader.toggleClass('site-header-formatter--sticky', false);
        }
        _setSubMenuPosition();

        position = scroll;
      }

      // close the nav.
      function _closeGnav(keepActive) {
        $html.toggleClass('active-gnav', !!keepActive);
        $gnavMainSectionWrapper.attr('aria-expanded', !!keepActive);

        // Reset Header Nav Section elements state.
        $gnavMainSection.attr('aria-expanded', 'false');
        $gnavMainSectionContent.attr('aria-hidden', 'true');

        // Reset Section Formatter elements state.
        $allSectionGroups.attr('aria-expanded', 'false');
        $allSectionGroupContent.attr('aria-hidden', 'true');

        // Reset Links state.
        $gnavLinks.prop('checked', false).change();
        $gnavLinks.siblings('.gnav-links__content').attr('aria-expanded', !!isDesktop);

        if (!keepActive) {
          $mainSectionTriggerReset.prop('checked', true);
          $gnavMainSectionWrapper.scrollTop(0);
        }
      }

      function _gnavHeaderTrigger() {
        var timeout;

        // Calculate desktop for mobile menu stuff.
        if (!!isDesktop) {
          $gnavMainSectionTrigger
            .off('click.siteHeader touchstart.siteHeader')
            .on('click.siteHeader touchstart.siteHeader', function (e) {
              var $self = $(this);

              _gnavPcTrigger($self, e);
            })
            .off('mouseover')
            .on('mouseover', function (e) {
              var $self = $(this);

              // Delay the opening of the Gnav menu.
              timeout = setTimeout(function () {
                _gnavPcTrigger($self, e);
              }, 200);
            })
            .off('mouseleave')
            .on('mouseleave', function () {
              clearTimeout(timeout);
            });
        } else {
          $gnavMainSectionTrigger
            .off('mouseover.siteHeader click.siteHeader touchstart.siteHeader')
            .on('click.siteHeader', function (e) {
              var $self = $(this);

              _gnavMobileTrigger($self, e);
            });
        }
      }

      function _gnavPcTrigger($self) {
        var $parent = $self.parents('.js-header-nav-section__section-v1');
        var $input = $parent.prev();
        var $navBar = $parent.closest('.js-site-header-formatter__sections');
        var $content = $parent.find('.js-header-nav-section__section-content');
        var $subNav = $content.find('.js-gnav-section-formatter-v1').first();
        var $subNavcontent = $subNav.find('.js-gnav-section-formatter__content-wrapper');
        var keepActive = $content.length ? true : false;
        var $icon = $parent.find('.header-nav-section__arrow-icon');
        var iconHeight = 10;
        if ($parent.attr('aria-expanded') === 'true') {
          return;
        }

        if ($icon.length > 0) {
          var topLink = $parent.offset().top - $navBar.offset().top;
          $icon.css({ top: $navBar.height() - topLink - iconHeight + 'px' });
        }

        _closeGnav(keepActive);
        if ($content.length) {
          $input.prop('checked', true);
          $parent.attr('aria-expanded', 'true');
          $content.attr('aria-hidden', 'false');
          $gnavMainSectionWrapper.attr('aria-expanded', 'true');
          $subNav.attr('aria-expanded', 'true');
          $subNavcontent.attr('aria-hidden', 'false');
          if ($body.hasClass('not-prod')) {
            _updateGnavTop($content);
          }
        }
      }

      function _updateGnavTop($content) {
        var $cmsToolbar = $('#toolbar', context);
        var $headerFormatter = $('.site-header-formatter__inner', context);
        var toolbarHeight = 0;
        var toolbarGnavHeight = 0;
        var headerHeight = 0;

        if ($cmsToolbar.length > 0) {
          headerHeight = $headerFormatter.height();
          toolbarHeight = $cmsToolbar.height();
          toolbarGnavHeight = headerHeight + toolbarHeight;
          $content.css('top', toolbarGnavHeight + 'px');
          $triggerResetLabel.css('top', toolbarGnavHeight + 'px');
        }
      }

      function _gnavMobileTrigger($self) {
        var $parent = $self.parents('.js-header-nav-section__section-v1');
        var $input = $parent.prev();
        var $content = $parent.find('.js-header-nav-section__section-content');
        var $subNav = $parent.find('.js-gnav-section-formatter-v1');
        var $links = $parent.find('.gnav-links');
        var sectionsCount = $links.length === 1;

        if ($subMenu.length > 0) {
          $subMenu.css({ top: '0px' });
        }

        _closeGnav(true);
        if (!$content.length) {
          return;
        }
        $input.prop('checked', true);
        $html.toggleClass('active-gnav', true);
        $links.toggleClass('only-gnav-link', sectionsCount);
        $gnavMainSectionWrapper.attr('aria-expanded', 'true');
        $subNav.attr('aria-expanded', 'true');
        $parent.attr('aria-expanded', 'true');
        $content.attr('aria-hidden', 'false');
      }

      function _toggleMobileMenu() {
        $html.toggleClass('active-gnav');
        if ($html.hasClass('active-gnav')) {
          $gnavMainSectionWrapper.attr('aria-expanded', 'true');
        } else {
          _closeGnav();
        }
      }

      $template.on('keydown', function (event) {
        var key = event.key;

        if (key === 'Escape') {
          $(document).trigger('check_reset_gnav_radio_buttom');
        }
      });

      // Subnav updates using js only.
      if (isDesktop) {
        $gnavMainSectionWrapper.attr('aria-expanded', 'true');
        $gnavLinks.siblings('.gnav-links__content').attr('aria-expanded', 'true');
      }

      if (hideHeaderOnScroll) {
        $(window)
          .off('scroll.siteHeader')
          .on('scroll.siteHeader', _.throttle(_updateNavStatus, 100));
        _updateNavStatus();
      }

      // Subnav updates using js only.
      function _expandElements() {
        if (!!isDesktop) {
          $gnavMainSectionWrapper.attr('aria-expanded', 'true');
          $gnavLinks.siblings('.gnav-links__content').attr('aria-expanded', 'true');
        }
      }

      Unison.on('change', function () {
        bps = Unison.fetch.all();
        bp = Unison.fetch.now();
        self.isMobile = self.checkIfMobile();
        isDesktop = parseInt(bp.width, 10) >= parseInt(bps['usn-largest'], 10);
        _expandElements();
        _closeGnav();
        _gnavHeaderTrigger();
        _navHeight();
      });

      _gnavHeaderTrigger();

      $triggerResetLabel
        .off('mouseover.siteHeader click.siteHeader')
        .on('click.siteHeader', function () {
          if (!$html.hasClass('ada-enabled')) {
            _closeGnav();
          }
        })
        .on('mouseover.siteHeader', function () {
          if ($(this).hasClass('site-header-formatter-overlay') && !$html.hasClass('ada-enabled')) {
            $(document).trigger('check_reset_gnav_radio_buttom');
          }
        });

      // Mobile only icon takes user back to main gnav sections display and collapses all open sections
      $gnavMobileSectionsMenu.once().on('click', function () {
        _closeGnav(true);
      });

      $gnavLinks.once().on('click', function () {
        var expand = $(this).is(':checked');

        $(this).siblings('.gnav-links__content').attr('aria-expanded', expand);
      });

      $gnavHamburger.once().on('click', _toggleMobileMenu);

      $subMenuLinks.once().on('click', function () {
        var $selectedLink = $(this);
        $subMenuLinks.removeClass('active');
        $selectedLink.addClass('active');
      });

      if ($subMenuLinks.length > 0 || $secondaryNavLinks.length > 0) {
        $(window).scroll(
          _.debounce(function () {
            _toggleActiveSubnav();
          }, 10)
        );
      }

      function _toggleActiveSubnav() {
        var currentScroll = $(window).scrollTop();
        var screenHeight = $(window).height();
        var isHeaderVisible =
          $headerFormatterInner.length > 0 &&
          $headerFormatterInner.position().top - currentScroll > -10;
        var toggleOffset = isHeaderVisible ? screenHeight / 3 : screenHeight / 4;
        $customGrids.each(function () {
          var $grid = $(this);
          var anchorIdString = $grid.find('.js-mpp-custom-anchor, .js-mpp-custom__anchor').attr('id') || null;
          var anchorId = anchorIdString !== null ? anchorIdString.replace(/\W/g, '') : '';
          var updatedSecNav = $secondaryNavLinks && $secondaryNavLinks.length > 0 ? true : false;
          var $link = updatedSecNav
            ? $secondaryNavLinks.find('a[href=#' + anchorId + ']')
            : $subMenuLinks.find('a[href=#' + anchorId + ']');
          var position = $grid.offset().top - currentScroll;
          if ($link.length > 0 && position <= toggleOffset) {
            if (updatedSecNav) {
              $secondaryNavLinks.removeClass('active');
              $link.closest('.js-gnav-content-link').addClass('active');
            } else {
              $subMenuLinks.removeClass('active');
              $link.parent().addClass('active');
            }
          }
        });
      }

      // Close Nav dropdown when moving the mouse out or clicking outside of it:
      $html.once().on(
        'click mouseout mouseover',
        _.debounce(function (event) {
          if (
            !!isDesktop &&
            $html.hasClass('active-gnav') &&
            !$html.hasClass('ada-enabled') &&
            ((event.type === 'mouseout' && !event.relatedTarget) ||
              !$(event.target).parents('.site-header-formatter__header').length)
          ) {
            _closeGnav();
          }
        }, 200)
      );

      $(document).on('hide_header_offer_banner', function () {
        $template.toggleClass('header-offers-banner-hidden', true);
      });

      $(document).on('check_reset_gnav_radio_buttom', function () {
        _closeGnav();
        $mobileGnavTrigger.removeProp('checked');
      });

      $mobileSearchInput.once().on('click', function (e) {
        e.preventDefault();
        e.stopPropagation();
        $searchIcons.first().focus().trigger('click');
      });
    },
    disablePopup: function() {
      var referrerOrigin = document.referrer.toLowerCase();
      var $modalElement = $('.site-header__wrapper');
      
      if (referrerOrigin.length > 0  && $modalElement.length > 0 && referrerOrigin.includes('shop.maccosmetics.com.br')) {
        $modalElement.removeClass('site-header__wrapper');
      }
    },
    checkIfMobile: function () {
      if (!!Unison.fetch.now()) {
        currentBpWidth = parseInt(Unison.fetch.now().width, 10);
        bPlargeUp = parseInt(Unison.fetch.all()['usn-medium'], 10);

        return currentBpWidth <= bPlargeUp;
      }

      return false;
    }
  };
  var headerBlockEnabled = Drupal.settings.globals_variables.header_block_enabled_by_referer;
  if (headerBlockEnabled) {
     behavior.disablePopup();
  }
  return behavior;
})(jQuery);
